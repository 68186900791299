import { Suspense, useEffect } from 'react';
import LeftNav from '../components/layout/LeftNav';
import TopNav from '../components/layout/top-menu/TopNav';
import PageLoader from '../components/shared/page-loader/PageLoader';
import { FCWithChildren } from '../types/FCWithChildren';
import IntercomUtils from '../utils/IntercomUtils';
import { useLayoutMargins } from '../global-state/Layout';

const LoggedInLayout: FCWithChildren = (props) => {
  const { children } = props;
  const setLayoutMargins = useLayoutMargins((x) => x.setValue);

  useEffect(() => {
    setLayoutMargins('ml-20');

    return () => setLayoutMargins('');
  }, [setLayoutMargins]);

  useEffect(() => {
    IntercomUtils.hideDefaultLauncher();
  }, []);

  return (
    <>
      <aside className="z-left-nav fixed flex h-full flex-col items-center">
        <LeftNav />
      </aside>

      <div className={'ml-20 flex h-full max-h-screen w-full flex-col'}>
        <TopNav />

        <div className="relative flex-grow overflow-auto pt-0">
          <Suspense fallback={<PageLoader loading isSuspense />}>{children}</Suspense>
        </div>
      </div>
    </>
  );
};

export default LoggedInLayout;
