import { FC, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import DropDownIcon from '../../shared/icon/composite/DropDownIcon';
import LoggedInUser from './LoggedInUser';
import { ProfileImageStack, ImageSize } from '../../ownership/ProfileImageStack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePbkAuth } from '../../../contexts/PbkAuthContext';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useCurrentUser } from '../../../global-state/Auth';
import { useCurrentClient } from '../../../global-state/Clients';

const LoggedInUserDropdown: FC = () => {
  const [open, setOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const auth = usePbkAuth();
  const profile = useCurrentUser((x) => x.value);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const client = useCurrentClient((x) => x.value);

  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    placement: 'bottom-end',
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const doLogout = (): void => {
    Sentry.setUser(null);
    auth.logout();
  };

  const goToSettings = (tab: string) => {
    if (client) {
      navigate(`/clients/${client?.id}/user-settings#${tab}`);
    } else {
      navigate(`/user-settings#${tab}`);
    }
    setOpen(false);
  };

  return (
    <div ref={popupRef}>
      <div className="relative flex cursor-pointer select-none items-center pr-4" ref={refs.setReference} {...getReferenceProps()}>
        <div>
          <LoggedInUser />
        </div>
        <div className="mx-2 inline-flex" tabIndex={-1}>
          {profile && <ProfileImageStack users={[profile]} size={ImageSize.S} />}
        </div>
        <DropDownIcon className="h-4 w-4" open={open} />
      </div>
      {open && (
        <FloatingFocusManager context={context} modal={false}>
          <div className="!h-auto" ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1000 }} {...getFloatingProps()}>
            <ul className="border-1 w-44 border-gray-200 bg-white shadow-md">
              <li
                className="cursor-pointer select-none border-t px-3 py-1 hover:bg-gray-200"
                {...mouseAndKeyboardCallbackProps(() => goToSettings('details'))}
              >
                {t('profile.view-profile')}
              </li>
              <li className="cursor-pointer select-none px-3 py-1 hover:bg-gray-200" {...mouseAndKeyboardCallbackProps(doLogout)}>
                {t('profile.log-out')}
              </li>
            </ul>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
};

export default LoggedInUserDropdown;
