import { FC } from 'react';
import LoggedInUserDropdown from './LoggedInUserDropdown';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import NotificationDropdown from '../../notifications/NotificationDropdown';
import HelpTrigger from './HelpTrigger';
import LanguageDropdown from './LanguageDropdown';
import CurrentUserService from '../../../services/CurrentUserService';
import StorageService from '../../../services/StorageService';
import { useInConsultantSpace, useTopNavHeading } from '../../../global-state/Workspace';
import { useCurrentClient } from '../../../global-state/Clients';
import { useCurrentUser } from '../../../global-state/Auth';
import { useShallow } from 'zustand/react/shallow';

export const TopNav: FC = () => {
  const client = useCurrentClient((x) => x.value);
  const inConsultantDashboard = useInConsultantSpace((x) => x.value);
  const topNavHeading = useTopNavHeading((x) => x.value);
  const [currentUser, setCurrentUser] = useCurrentUser(useShallow((x) => [x.value, x.setValue]));

  const topHeading = topNavHeading || (!inConsultantDashboard && client?.name);

  const updateUserPreferredLanguage = (languageCode: string) => {
    if (currentUser) {
      CurrentUserService.updateProfile({
        ...currentUser,
        languageCode: languageCode,
      }).then((res) => {
        StorageService.setLang(res.data.language);
        setCurrentUser({ ...currentUser, language: res.data.language });
      });
    }
  };

  return (
    <div className="border-gray-5 flex min-h-28 flex-col justify-between border-b-2 bg-white pt-4">
      <div className="flex w-full items-center justify-between">
        <div className={`flex ${topHeading ? 'flex-col justify-between' : 'items-center'} h-full`}>
          <div className="relative flex items-center gap-2 pl-6">
            {topHeading && (
              <Heading size={HeadingSize.H1} actualSize={HeadingSize.H5}>
                {topHeading}
              </Heading>
            )}{' '}
            <div id="portal-top-nav-tenant"></div>
          </div>
        </div>
        <div className="piped-items flex items-center justify-end">
          <div className="mr-5 pt-1">
            <div className="flex items-center gap-3">
              <NotificationDropdown />
              <HelpTrigger />
            </div>
          </div>
          <div className="mr-6 pt-1">
            <LanguageDropdown onChange={updateUserPreferredLanguage} />
          </div>
          <LoggedInUserDropdown />
        </div>
      </div>
      <div id="portal-top-nav" className="p-2 pl-6"></div>
    </div>
  );
};

export default TopNav;
