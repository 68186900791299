import { FC } from 'react';
import { useCurrentUser } from '../../../global-state/Auth';

const LoggedInUser: FC = () => {
  const user = useCurrentUser((x) => x.value);

  return (
    <span data-sensitive="true" className="mr-2 font-medium">
      {user?.firstName} {user?.lastName}
    </span>
  );
};

export default LoggedInUser;
